import { useRouter } from 'next/router';
import React, { memo, useCallback, useEffect } from 'react';

import { routes } from 'src/constants/navigation';
import { useProgressSteps } from 'src/utils';

import { useAnalytics } from '@features/Analytics';
import { FormattedMessage } from '@features/intl';

import styles from './FirstScreenLoading.module.scss';

const injectStyles = `
  html {
    min-height: 100%;
  }
  body {
    display: block;
  }
  body,
  #__next {
    flex: 1;
  }
  html,
  body,
  #__next {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
  }
 `;

export const FirstScreenLoading = memo(
  ({
    setAnimationFinished,
  }: {
    setAnimationFinished: (newState: boolean) => void;
  }) => {
    const router = useRouter();
    const { trackGoogleEvent } = useAnalytics();
    const { progress } = useProgressSteps({
      started: true,
      progressDurations: [500, 2000, 200, 2000, 1300],
      steps: [20, 50, 50, 80, 100],
    });

    const updateAnimationShowState = useCallback(
      async (newState: boolean) => {
        await new Promise((resolve) => setTimeout(resolve, 6000));
        setAnimationFinished(newState);
      },
      [setAnimationFinished],
    );

    useEffect(() => {
      trackGoogleEvent({
        eventName: 'onboarding_loader_opened',
      });
      router.prefetch(routes.quiz).then((r) => r);
      updateAnimationShowState(true).then((r) => r);
    }, [router, trackGoogleEvent, updateAnimationShowState]);

    return (
      <>
        <style>{injectStyles}</style>
        <div
          className={styles.host}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            background: "url('/images/loader-bg.webp') no-repeat #fff",
            backgroundSize: 'cover',
            backgroundPositionX: 'center',
            height: '100%',
            // @ts-ignore
            height: '100vh',
            // @ts-ignore
            height: '100dvh',
            color: '#fff',
          }}>
          <div
            className={styles.bottomSection}
            style={{
              background:
                'linear-gradient(180deg, rgba(0, 0, 0, 0) 0.06%, #000 65.06%)',
              padding: '0 24px 40px',
            }}>
            <div
              style={{
                maxWidth: '435px',
                margin: '0 auto',
              }}>
              <h1 className={styles.title}>
                <FormattedMessage
                  defaultMessage="Crush Your Fitness Goals Fast with Wall Pilates by Youth 🔥"
                  id="Onboarding.FirstLoader.Title"
                />
              </h1>

              <p className={styles.description}>
                <FormattedMessage
                  defaultMessage="Get 12 week personalized plan"
                  id="Onboarding.FirstLoader.Subtitle"
                />
              </p>

              <p className={styles.additionalBlock}>
                <span className={styles.loader} />
                <FormattedMessage
                  defaultMessage="Loading the quiz..."
                  id="Onboarding.FirstLoader.Subtitle.Loading"
                />
              </p>
              <div className={styles.progressContainer}>
                <div
                  className={styles.progressFill}
                  style={{ width: `${progress}%` }}
                />
                <span className={styles.progressTitle}>
                  {progress.toFixed(0)}%
                </span>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  },
);

FirstScreenLoading.displayName = 'FirstScreenLoading';
