import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';

import { Loader } from 'src/components/common/Loader';

import { useStores } from '../components/common/root-store-provider/root-store-provider';
import page from '../components/pages/page/page';
import { routes } from '../constants/navigation';

const Index = observer(() => {
  const router = useRouter();

  const { authStore } = useStores();
  const { user, setAccessCode, setLoginedByWinback } = authStore;
  const { query, isReady } = router;

  const emailToken =
    typeof query['email_token'] === 'string' ? query['email_token'] : undefined;

  const accessCode =
    typeof query['access_code'] === 'string' ? query['access_code'] : undefined;

  const redirect = user !== undefined && isReady && emailToken === undefined;

  useEffect(() => {
    (async () => {
      try {
        if (emailToken) {
          await authStore.loginByEmailToken({
            emailToken: emailToken,
          });
        }
      } catch (error) {
        if (error) {
          const newQuery = { ...query };
          delete newQuery['email_token'];
          delete newQuery['access_code'];
          // noinspection JSIgnoredPromiseFromCall
          router.replace({ pathname: routes.quiz, query: newQuery });
        }
      }
    })();
  }, [authStore, query, emailToken, router]);

  useEffect(
    () => {
      if (emailToken && accessCode) {
        setAccessCode(accessCode);
        setLoginedByWinback(true);
        const newQuery = { ...query };
        delete newQuery['email_token'];
        // noinspection JSIgnoredPromiseFromCall
        router.replace({ pathname: routes.newPlanPaywall, query: newQuery });
      }
    },
    // https://github.com/vercel/next.js/issues/18127
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [emailToken, user],
  );

  useEffect(
    () => {
      if (redirect) {
        if (user) {
          // noinspection JSIgnoredPromiseFromCall
          router.replace(routes.newPlanPaywall);
        } else {
          // noinspection JSIgnoredPromiseFromCall
          router.replace(routes.quiz);
        }
      }
    },
    // https://github.com/vercel/next.js/issues/18127
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user, user?.isSubscriptionActive],
  );

  return <Loader />;
});

export default page(Index);
