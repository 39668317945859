import * as Sentry from '@sentry/react';
import { observer } from 'mobx-react-lite';
import { NextParsedUrlQuery } from 'next/dist/server/request-meta';
import { useRouter } from 'next/router';
import React, { ComponentType, useEffect, useMemo, useState } from 'react';

import { FirstScreenLoading } from 'src/components/common/FirstScreenLoading';
import { Loader } from 'src/components/common/Loader';
import { routes } from 'src/constants/navigation';
import { compareDatesInHours } from 'src/utils/compareDates';
import { useAbortableEffect } from 'src/utils/hooks/use-abortanle-callback';

import { useStores } from '../../common/root-store-provider/root-store-provider';

const lastActiveStep =
  typeof window !== 'undefined' &&
  window.localStorage.getItem('lastActiveStepIndex');

const OUTDATED_USER_SESSION_HOURS = 3;

const page = <T extends object>(Component: ComponentType<T>) =>
  observer((props: T) => {
    const [mounted, setMounted] = useState(false);
    const [isAnimationFinished, setAnimationFinished] = useState(false);
    const {
      authStore: { variant, setVariant, fetchUser, auth_token, user, logOut },
      quizStore,
      analyticsStore: { segment, getAbTests, reset: resetAnalytics },
      paymentsStore,
    } = useStores();
    const router = useRouter();
    const { pathname, route, query, isReady } = router;

    const result = useMemo(() => {
      if (!route.includes(routes.quiz) && (!mounted || user === undefined)) {
        return <Loader />;
      } else if (pathname === routes.quiz && !isAnimationFinished) {
        return (
          <FirstScreenLoading setAnimationFinished={setAnimationFinished} />
        );
      } else {
        return <Component {...props} />;
      }
    }, [pathname, isAnimationFinished, route, mounted, user, props]);

    useEffect(() => {
      setMounted(true);
    }, []);

    useAbortableEffect(
      (abortSignal) => {
        // noinspection JSIgnoredPromiseFromCall
        fetchUser({ token: auth_token, abortSignal: abortSignal })
          .then((r) => r)
          .catch((error) => {
            if (error.name === 'AbortError') {
              console.log('AbortError: Fetch request aborted');
              return;
            }
            Sentry.captureException(error.name);
          });
      },
      [fetchUser],
    );

    const variantFromQuery = query['variant'];
    const hardReset = query['hard_reset'];

    const resetQuizAndLogout =
      pathname === routes.quiz && isReady && (!!query['reset'] || !!hardReset);

    useEffect(() => {
      //Set variant from query and replace variant if query with variant changed
      if (
        variantFromQuery &&
        variant !== variantFromQuery &&
        typeof variantFromQuery === 'string'
      ) {
        setVariant(variantFromQuery);
      } else if (
        // Set variant to undefined if reset is true and user have active variant in store
        variantFromQuery === undefined &&
        variant !== undefined &&
        (!!query['reset'] || !!hardReset)
      ) {
        setVariant(undefined);
      }
    }, [query, setVariant, variantFromQuery, variant, hardReset]);

    const isUserSessionOutdated = useMemo(() => {
      if (variantFromQuery !== variant) {
        return true;
      }

      const firstLoginDateFromStorage =
        typeof window !== 'undefined' &&
        window.localStorage.getItem('firstLoginDateAndTime');

      return (
        compareDatesInHours(firstLoginDateFromStorage) >=
        OUTDATED_USER_SESSION_HOURS
      );
    }, [variantFromQuery, variant]);

    useEffect(() => {
      if (resetQuizAndLogout && (isUserSessionOutdated || hardReset)) {
        quizStore.reset();
        resetAnalytics();
        paymentsStore.resetPayment();
        logOut().then((r) => r);
        localStorage.clear();
        sessionStorage.clear();
      }
    }, [
      hardReset,
      isUserSessionOutdated,
      logOut,
      paymentsStore,
      quizStore,
      resetAnalytics,
      resetQuizAndLogout,
    ]);

    useEffect(
      () => {
        if (resetQuizAndLogout && !user) {
          const { pathname, query } = router;
          const newQuery: NextParsedUrlQuery = {
            ...query,
            step:
              lastActiveStep && !isUserSessionOutdated ? lastActiveStep : '0',
          };
          delete newQuery['reset'];
          delete newQuery['hard_reset'];
          // noinspection JSIgnoredPromiseFromCall
          router.replace({ pathname, query: newQuery });
        }
      },
      // https://github.com/vercel/next.js/issues/18127
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [resetQuizAndLogout, user],
    );

    useAbortableEffect(
      (abortSignal) => {
        if (!segment && query['reset'] === undefined && isReady) {
          getAbTests({
            variant: variant ?? '',
            utm_source: (query['utm_source'] as string) ?? '',
            utm_campaign: (query['utm_campaign'] as string) ?? '',
            region: (query['region'] as string) ?? '',
            abortSignal,
          }).then((r) => r);
        }
      },
      [getAbTests, isReady, query, segment, variant],
    );

    // The check on `mounted` helps avoid hydration DOM mismatch
    // that happens because `user` is always empty during SSR,
    // but may be `null` if the user is not logged in
    return result;
  });

export default page;
